import Vue from 'vue/dist/vue.js'
import Router from 'vue-router'
import CategoryView from '../components/CategoryView.vue'
import SingleArticleView from '../components/SingleArticleView.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: CategoryView,
      props: (route) => ({ query: route.query.page })
    },
    {
      path: '/:category_id/',
      name: 'category',
      component: CategoryView,
      props: (route) => ({ query: route.query.page })
    },
    {
      path: '/post/:post_id',
      name: 'SinglePost',
      component: SingleArticleView
    },
    {
      path: '/post/:post_id/:slug',
      name: 'SinglePostWithSlug',
      component: SingleArticleView
    },
  ]
})
