import Vue from 'vue/dist/vue.js'
import router from './router'
import VueAxios from 'vue-axios'
import { securedAxiosInstance, plainAxiosInstance } from './axios'
import searchItemTemplate from './components/search-item-template.vue'
import CarbonAds from './components/CarbonAds'
import Autocomplete from 'v-autocomplete'
import VueGtag from "vue-gtag"

import 'v-autocomplete/dist/v-autocomplete.css'
import VueMasonry from 'vue-masonry-css'

Vue.use(VueMasonry)

Vue.use(Autocomplete)

Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})

Vue.use(VueGtag, {
  config: { id: 'UA-56709114-2' }
}, router)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    router,
    components: {
      CarbonAds
    },
    data: {
      categories: [],
      isSearching: false,
      searchtext : '',
      searchItem: null,
      searchItems: [],
      template: searchItemTemplate,
      isCollapsed: false,
      page: 1,
    },
    created() {
      this.getCategories()
    },
    methods: {
      getCategories () {
        this.$http.secured.get('categories')
        .then(response => {
          this.categories = response.data
        })
        .catch(error => this.error = 'Something went wrong')
      },
      toggleSearch: function() {
        this.isSearching = !this.isSearching
        this.item = null
      },
      getLabel (searchItem) {
        return searchItem.attributes.name
      },
      updateItems (text) {
        console.log(text)
        this.searchItems = this.categories.filter(item => item.attributes.name.includes(text))
      },
      itemSelected (item) {
        this.selectCategory(item)
        this.isSearching = false
        this.item = null
      }
    }
  })
})
