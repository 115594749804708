<template>
  <div class="row gap-20 masonry pos-r">
    <div class="masonry-sizer col-md-12">
      <h3> {{ category.name }} ({{ pagination.total_items }})</h3>
    </div>
    <div class="masonry-item  w-100">
      <masonry
        :cols="{default: 4, 1000: 3, 700: 2}"
        :gutter="10"
      >
        <div v-for="article in articles" :key="article.id">
          <a :href="article.attributes.demo_link" target="_blank">
          <div class='grid-item'>

            <img :src="article.attributes.preview" style="max-width: 100%;" />

            <h6 class='py-2' style="padding-left: 10px; padding-right: 10px;"> {{article.attributes.name}} by {{article.attributes.author}} </h6>
          </div>
          </a>
        </div>
      </masonry>


    </div>
    <div class="masonry-item w-100">
      <paginate
        v-model="page"
        :page-count="pagination.total_pages"
        :click-handler="changePage"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination pull-right'"
        :page-class="'page-item'">
      </paginate>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'CategoryView',
  components: {
    Paginate
  },
  data: function () {
    return {
      articles: [],
      pagination: {total_pages: 1, total_items: 0},
      category: {},
      page: (parseInt(this.$route.query.page) || 1)
    }
  },
  beforeRouteEnter (to, from, next) {
    next( vm => {
      vm.getAllArticles(to.params.category_id)
      next()
    })
  },
  watch: {
    $route () {
      this.getAllArticles(this.$route.params.category_id)
    }
  },
  methods: {
    getAllArticles (categorySlug) {
      this.page = parseInt(this.$route.query.page) || 1
      this.$http.secured.get('/articles', {
        params: { page: this.page, category_slug: categorySlug }
      })
      .then(response => {
        this.articles = response.data.articles.data
        this.pagination = response.data.pagination
        this.category = response.data.category
        document.title = this.category.name + ' | NavNav+'
        window.scrollTo(0,0)
      })
      .catch(error => this.error = 'Something went wrong')
    },
    changePage () {
      this.$router.push({query: {page: this.page}})
    }
  }
}
</script>
