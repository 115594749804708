<template>
  <div class="row gap-20 masonry pos-r">
    <div class="masonry-sizer col-md-12">
      <h3> {{ article.name }} by {{ article.author }}</h3>
    </div>
    <div class="masonry-item  w-100">
      <masonry
        :cols="{default: 4, 1000: 3, 700: 2}"
        :gutter="10"
      >
        <a :href="article.demo_link" target="_blank">
        <div class='grid-item'>

          <img :src="article.preview" style="max-width: 100%;" />

          <h6 class='py-2' style="padding-left: 10px; padding-right: 10px;">
            {{ article.name }} by {{ article.author }}
          </h6>
        </div>
        </a>
      </masonry>


    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleArticleView',
  components: {},
  data: function () {
    return {
      article: {}
    }
  },
  watch: {
    $route () {
      this.getSingleArticle()
    }
  },
  mounted () {
    this.getSingleArticle()
  },
  methods: {
    getSingleArticle () {
      this.$http.secured.get('/articles/' + this.$route.params.post_id)
      .then(response => {
        this.article = response.data.data.attributes
        document.title = this.article.name + ' | NavNav+'
        window.scrollTo(0,0)
      })
      .catch(error => this.error = 'Something went wrong')
    }
  }
}
</script>
